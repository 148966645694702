import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import { serializers } from "../../../helpers/blockContentSerializer.js"
import BlockContent from "@sanity/block-content-to-react"

class InfoPage extends React.Component {
  render() {
    let content = this.props.data.sanityInfoPage
    return (
      <Layout article={true}>
        <h2>{content.pageTitle}</h2>
        <BlockContent blocks={content._rawText} serializers={serializers} />
      </Layout>
    )
  }
}

export const query = graphql`
  query($slug: String) {
    sanityInfoPage(pageUrl: { current: { eq: $slug } }) {
      pageUrl {
        current
      }
      _rawText(resolveReferences: { maxDepth: 3 })
      pageTitle
    }
  }
`
export default InfoPage
